/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-commissions.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <p className="spread">
        SimCrest Commissions streamlines the process of handling commissions for
        sales reps and external sales resources. Commissions are easily
        reconciled to the general ledger and the solution keeps detailed records
        of commissions for all sales documents and automatically posts
        commission expenses and keeps track of your accounts payable. Best of
        all, once it’s all set up, there is hardly anything else to do.
      </p>
      <span>Features and Benefits</span>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Solution is fully integrated with the general ledger, including accounts payables.`,
          },
          {
            text: `All commissions are expensed as accounts payable entries with an option to put the entry on hold until the original sales document has been paid.`,
          },
          {
            text: `Calculate commissions based on revenue or margin.`,
          },
          {
            text: `A separate commission journal makes it possible to make manual entries to the commission ledger and associate with existing commission entries to allow commission payouts to be based on cash receipt on sales documents.`,
          },
          {
            text: `Commission splits for all documents including, sales orders, sales invoices, sales return orders and sales credit memos. Customers can have default splits that will be suggested when creating sales documents.`,
          },
          {
            text: `Automatic cash application of cash to commission ledger and taking vendor ledgers off hold.`,
          },
          {
            text: `All commission transactions are registered into a commission ledger with easy one-click access to original sales documents, A/R ledger, and A/P ledger.`,
          },
          {
            text: `All commission transactions are registered into a commission ledger with easy one-click access to original sales documents, A/R ledger, and A/P ledger.`,
          },
          {
            text: `A separate commission journal that allows manual commission entries. If a journal entry is applied to an existing ledger entry, the system can cascade closing of entries through the application chain and take the vendor ledger off hold automatically for all involved entries.`,
          },
        ]}
      />
      <div>
        <img src={TitleImg} />
        <div>
          <p>
            SimCrest Commissions is easy to use and saves you a lot of time!
            <br />
            <br />
            Commissions can be acquired as an App for Dynamics 365 Business
            Central On Premise only (no online version available).
          </p>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="Commissions"
      description="SimCrest Commissions streamlines the process of handling commissions for sales reps and external sales resources."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="Commissions for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      videos={[
        {
          title: "Demo of Commissions App",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/E2Hfg3HycvA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
      ]}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-commissions.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-commissions.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-commissions.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
